.btn
    margin: 0
    padding: 0
    display: flex
    align-items: center
    justify-content: center
    border: none
    background-color: transparent
    color: inherit
    font-size: inherit
    font-weight: 300
    font-family: inherit
    text-decoration-color: transparent
    gap: 0.25em
    @media screen and (min-width: 768px)
        gap: 0.5em

    &__icon__container
        display: flex
        align-content: center
        justify-content: center
        align-items: center
        justify-items: center
        width: 1em
        height: 1em
        border-radius: 50%
        padding: 0.5em
        margin: 0
        background-color: #3F485F
        color: #E8D6C2
        > .icon
            width: 1em
            height: 1em

    &:hover
        cursor: pointer

    &.btn__outlined
        border: 1px solid
        padding: 0.5em 1em
        border-radius: 1.5em
        border-color: #3F485F
        background-color: transparent
        @media(hover:hover)
            &:hover
                background-color: #3F485F
                color: #E8D6C2
    &.btn__filled
        border-radius: 1.5em
        background-color: #3F485F
        color: #E8D6C2
        & .btn__icon__container
            background-color: transparent
            padding: 0

    &.btn__inline
        display: inline-flex
        align-items: baseline
        flex-direction: row-reverse
        color: inherit

        & > .btn__icon__container
            align-self: center
            vertical-align: baseline
            display: inline-block
            padding: 0
            background-color: transparent
            color: inherit

        & > .btn__text__container
            display: inline-block
            text-decoration: underline

    .light &
        color: #252525

    .primary &,
    .dark &
        color: #E8D6C2

    &.primary
        color: #3F485F
