@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap')
@import "https://unpkg.com/open-props/easings.min.css"
/*Typography*/
html, body
    color-scheme: dark light
    font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    font-size: 14px
    font-weight: 300
    margin: 0
    @media screen and (min-width: 768px)
        font-size: 16px
    @media screen and (min-width: 1024px)
        font-size: 18px
    @media screen and (min-width: 1440px)
        font-size: 20px

input,
textarea,
code
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

h1
    font-size: 3rem
    font-weight: 400
    letter-spacing: 0.7rem
    margin: 0
    margin-bottom: 1rem
    line-height: 80%
    @media screen and (min-width: 768px)
        font-size: 3.5rem
    @media screen and (min-width: 1024px)
        font-size: 4rem

h2
    font-size: 1.25rem
    font-weight: 300
    text-transform: uppercase
    margin: 0
    margin-bottom: 0.5rem

h3
    font-size: 1.25rem
    font-weight: 600
    letter-spacing: 0.3em
    text-transform: uppercase
    margin: 0

h4
    font-size: 1rem
    font-weight: 600
    margin: 0

h5
    font-size: 1rem
    font-weight: 400
    margin: 0

input,
textarea,
h6
    font-size: 0.8rem
    letter-spacing: 0.15em
    font-weight: 400
    margin: 0

p
    margin: 0
    margin-bottom: 0rem
    &.small
        margin-top: 0.5rem
        font-size: 0.8rem
        text-align: center

/* LAYOUT */
#root
    display: grid
    grid-template-rows: auto 1fr
    grid-template-areas: "header" "main"
    min-height: 100vh
    overflow: hidden
    width: 100%

.heading
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    margin-bottom: 2rem

header
    grid-area: header

main
    grid-area: main

.container
    margin: 2rem

    @media screen and (min-width: 768px)
        margin: 2rem auto
        max-width: calc(768px - 4rem)

    @media screen and (min-width: 1024px)
        max-width: calc(1024px - 4rem)

    @media screen and (min-width: 1440px)
        max-width: calc(1440px - 4rem)

/* COLORS */

.dark
    color: #E8D6C2
    background-color: #252525
    transition: background-color 0.5s var(--ease-3)
    transition-duration: 0.25s

.light
    color: #252525
    background-color: #E8D6C2
    transition: background-color 0.5s var(--ease-4)

.primary
    background-color: #3F485F
    color: #E8D6C2
    &.contrast
        background-color: #E8D6C2
        color: #3F485F

.light .text__contrast
    color: #252525

.dark .text__contrast
    color: #E8D6C2

.text__primary
    color: #3F485F

/* Shapes */ 
.circle
    aspect-ratio: 1/1
    border-radius: 50%
    clip-path: circle(50% at 50% 50%)

.wave
    transform: translate(0%, -1%)
    display: block
    width: 100%
    z-index: 1
    overflow: clip

.gutter
    background: radial-gradient(#3F485F 12%, transparent 12%)
    background-position: 50% 50%
    background-size: 4vmin 4vmin
    @media screen and (min-width: 768px)
        background-size: 2vmin 2vmin

/* ReCaptcha hiding */
.grecaptcha-badge
    visibility: hidden

footer
    display: inline-flex
    gap: 1rem
    flex-wrap: wrap
    flex-direction: row
    align-items: center
    justify-content: center
    padding: 1rem
