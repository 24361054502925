.projects
    display: flex
    flex-direction: column
    align-items: center
    padding-bottom: 8rem

    .container
        position: relative
        display: flex
        flex-direction: column

    &__list
        display: grid
        grid-template-columns: 1fr
        gap: 2rem
        justify-items: center
        align-items: center

        .circle
            position: absolute
            height: 80%
            background: radial-gradient(#252525 12%, transparent 12%)
            background-position: 50% 50%
            background-size: 4vmin 4vmin
            border-radius: 50%
            @media screen and (min-width: 768px)
                background-size: 2vmin 2vmin
                height: 100%
        @media screen and (min-width: 1024px)
            grid-template-columns: 1fr 1fr
            .circle
                height: 110%
