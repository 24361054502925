.project
    width: 100%
    border-radius: 1rem
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    box-sizing: border-box
    position: relative
    border: 1px solid #ffffff36
    overflow: hidden

    &__image
        object-fit: Cover
        object-position: 0% 0%
        width: 100%
        aspect-ratio: 16/9

    &__content
        box-sizing: border-box
        display: flex
        flex-direction: column
        justify-content: flex-end
        align-items: flex-start
        position: absolute
        height: 100%
        width: 100%
        gap: 0.25rem
        padding-block: 1rem

        h4,
        h5,
        p
            margin-inline: 1rem
        p
            margin-bottom: 1rem

.project__image__overlay
    position: absolute
    width: 100%
    height: 100%
    background: #3f485ff5
    opacity: 0.1
    transition: opacity 0.25s ease-in-out

.project__content__links
    border-top: 1px solid #ffffff36
    width: 100%
    display: flex
    flex-direction: row
    justify-content: space-around
    align-items: center
    gap: 1rem
    padding-top: 1rem

@media (hover:hover)
    .project
        & .project__image__overlay
            opacity: 0.2

        & .project__content
            transform: translate(0%, 100%)

        & .project__image
            transform: scale(1)

        @media(prefers-reduced-motion: no-preference)
            & .project__image
                transition: transform 0.5s var(--ease-2)

            & .project__image__overlay
                transition: opacity 0.25s var(--ease-2)

            & .project__content
                transition: transform 0.25s var(--ease-2)

        &:hover

            & .project__image__overlay
                opacity: 1

            & .project__content
                transform: translate(0%, 0%)

            & .project__image
                transform: scale(1.1)

@media (hover:none)

    .project .project__image__overlay
        opacity: 0.9
        background: linear-gradient(0deg, #3f485ff5 10%, #3f485f22 100%)

    .project .project__content
        position: relative

@media screen and (min-width: 768px)
    .project
        height: 100%
