.career
    .heading
        position: relative
        margin-top: 0
        display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: center
        gap: 1rem

    .image__container
        position: absolute
        bottom: 50%
        right: 0
        flex-grow: 1
        z-index: 1
        display: flex
        justify-content: flex-end
        @media screen and (min-width: 768px)
            bottom: 0

    .image
        border-radius: 50%
        padding: 2rem
        height: 30vmin
        width: 30vmin
        object-fit: cover
        .light &
            border: 1px solid #E8D6C2

        .dark &
            border: 1px solid #252525

        @media screen and (min-width: 768px)
            height: 25vmin
            width: 25vmin
            object-fit: cover
        @media screen and (min-width: 1440px)
            height: 30vmin
            width: 30vmin
            object-fit: cover

    &__content
        display: grid
        grid-template-columns: auto 1fr
        grid-template-rows: repeat(fit-content(2), 1fr)
        gap: 0 .5rem

        & > .visual
            display: flex
            flex-direction: column
            justify-content: flex-start
            align-items: center

        & > .visual > .circle
            display: flex
            justify-content: center
            align-items: center
            padding: 0.5em
            border: 1px solid

        & > .visual > .line
            flex-grow: 1
            border-left: 1px solid

        & > .text p
            margin-bottom: 1em
