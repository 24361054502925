nav
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    gap: 1rem
    margin: 2rem 2rem 1rem 2rem

    .btn
        font-size: 1.5rem

.spacer
    flex-grow: 1

#theme-toggle
    cursor: pointer
    touch-action: manipulation
    -webkit-tap-highlight-color: transparent

    & > .btn__icon__container
        inline-size: 1em
        block-size: 1em
        aspect-ratio: 1
        outline-offset: 5px

        & > svg
            inline-size: 100%
            block-size: 100%
            stroke-linecap: round

.sun-and-moon
    & > :is(.moon, .sun, .sun-beams)
        transform-origin: center center

    & > .sun-beams
        stroke-width: 2px

    @media(prefers-reduced-motion: no-preference)
        & > .sun
            transition: transform .5s var(--ease-elastic-3)

        & > .sun-beams
            transition: transform .5s var(--ease-elastic-4), opacity .5s var(--ease-3)

        & > .moon > circle
            transition: transform .5s var(--ease-elastic-3)

    .dark &
        & > .sun
            transform: scale(1.75)

        & > .sun-beams
            opacity: 0

        & > .moon > circle
            transform: translateX(-7px)

        @media(prefers-reduced-motion: no-preference)
            & > .sun
                transition-timing-function: var(--ease-3)
                transition-duration: .25s

            & > .sun-beams
                transform: rotateZ(-25deg)
                transition-duration: .15s

            & > .moon > circle
                transition-delay: .25s
                transition-duration: .25s
