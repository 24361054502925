.contact
    padding: 2rem 0

    .container
        max-width: 500px

    .error
        color: #ff0000
        font-size: 0.8rem
        margin: 0
        padding: 0
        margin-bottom: 0.5rem

    .gutter
        border-radius: 1rem
        background-image: radial-gradient(#252525 12%, transparent 12%)

    &__content > form
        background: linear-gradient(0deg, #3f485ff5 10%, #3f485f86 100%)
        display: flex
        flex-direction: column
        justify-content: stretch
        align-items: stretch
        width: 100%
        margin: 0 auto
        border: 1px solid #ffffff36
        border-radius: 1rem
        overflow: hidden

        & > div

        & > #email
            background: transparent
            color: #E8D6C2
            border: none
            border-bottom: 1px solid #ffffff36
            padding: 1rem 1rem 0.5rem 1rem

        & > #email:focus
            outline: 1px solid #ffffff00
            border: 1px solid #e8d6c2c2
            border-radius: 1rem 1rem 0 0

        & > #subject
            background: transparent
            color: #E8D6C2
            border: none
            border-bottom: 1px solid #ffffff36
            padding: 1rem 1rem 0.5rem 1rem

        & > #subject:focus
            outline: 1px solid #ffffff00
            border: 1px solid #e8d6c2c2
            border-radius: 0 0 0 0

        & > #message
            background: transparent
            color: #e8d6c2c2
            border: none
            padding: 1rem 1rem 0.5rem 1rem
            resize: none

            &:focus
                outline: 1px solid #ffffff00
                border: 1px solid #e8d6c2c2
                border-radius: 0 0 0 0

        & > .btn
            background: transparent
            color: #E8D6C2
            border: none
            border-top: 1px solid #ffffff36
            padding: 1rem

            &:hover
                color: #252525

                > .btn__icon__container
                    color: #252525
