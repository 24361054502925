.hero
    position: relative

    .wave
        transform: translate(0%, 1%)

    .container
        display: flex
        flex-direction: column
        justify-content: space-between
        align-items: center
        gap: 2rem
        position: relative
        width: auto
        margin-bottom: 0

    &__img
        width: 80%
        .circle
            background: radial-gradient(#3F485F 12%, transparent 12%)
            background-position: 0% 0%
            background-size: 4vmin 4vmin
            border-radius: 50%
            padding: 2rem

    &__content
        .links
            display: grid
            justify-content: space-between
            grid-template-columns: 1fr 1fr
            margin-top: 1rem
            gap: 1rem

        .btn
            justify-content: flex-start

.logo
    fill: #3F485F

@media screen and (min-width: 768px)
    .hero
        .container
            position: relative
            flex-direction: row
            justify-content: space-between
            align-items: center
            padding-bottom: 6rem

        &__content
            width: 60%

        &__img
            width: 30%
            .circle
                background-size: 2vmin 2vmin
